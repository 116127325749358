<template>
    <div class="create_idea">
        <CompHeader title="我发起的创意">
            <template v-slot:tips v-if="total">共 {{total}} 个创意</template>
        </CompHeader>
        <div v-if="isLoad">
            <template v-if="idealist.length">
                <CompIdeaList :list="idealist" :category_list="category_list" @change="getList"/>
                <CompPagination :total="total" :pageSize="page_size" @change="pageChange"/>
            </template>
            <CompError
                v-else
                class="cont-nothing-box"
                message="暂无发起的创意"
                />
        </div>
    </div>
</template>

<script>
import domain from '@/service/domain'
import { ElMessageBox } from "element-plus";
import CompHeader from "@@/common/header.vue"
import CompIdeaList from "@@/user/ideaList.vue"
import CompPagination from "@@/common/pagination.vue"
import CompError from "@@/common/error.vue"

import { getIdeaList, getIdeaCategoryList } from "@api/index"
export default {
    components: {
        CompHeader,
        CompIdeaList,
        CompPagination,
        CompError
    },
    computed: {},
    data() {
        return {
            isLoad: false,
            category_list:[],
            idealist: [],
            page: 1,
            page_size: 8,
            total: 0
        }
    },
    async created() {
        await this.getCategoryList()
    },
    async mounted() {

    },
    provide() {
        return {
            refresh: this.getList
        }
    },
    methods: {
        async getCategoryList() {
            const { status, data, message } = await getIdeaCategoryList({})
            if (status == 0 && data) {
                this.category_list.push(...data)
                this.getList()
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        async getList() {
            const { status, data, message } = await getIdeaList({
                page: this.page,
                page_size: this.page_size,
            })
            if (status == 0) {
                if(data){
                    this.total = data.total
                    this.idealist = data.list
                    this.idealist.forEach(ele => {
                        if(ele.refuse_reason && ele.refuse_reason.length > 32){
                            ele.needOpen = true;
                        }else{
                            ele.needOpen = false;
                        }
                    });
                }
                window.scrollTo(0, 0);
                this.isLoad = true;
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                this.isLoad = true
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        pageChange(val) {
            this.page= val
            this.getList()
        },
        goLogin(message) {
            ElMessageBox.confirm(
                message,
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }
            )
            .then(() => {
                location.href = domain.account
            })
            .catch(() => {
            
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .create_idea{
        // padding: 40px 0;
        margin: 0 auto;
        width: 800px;
    }
    .cont-nothing-box{
        padding: 120px 0 0 0;
    }
</style>
