<template>
    <el-drawer v-model="drawer" :title="title" :z-index="10000" class='timeDrawer' :before-close="handleClose">
        <div class="dialogContent">
            <p v-if="type == 'release'">定时发布最短时间为1小时，最长可设置7天。</p>
            <p v-else>在创意发布前10分钟均可随时修改定时，时间最短为1小时，最长可设置7天。</p>
            <div class="time">
                <div class="input hour">
                    <el-date-picker
                        :editable="false"
                        type="datetime"
                        :default-value="new Date(+new Date() + 60 * 60 * 1000)"
                        :disabled-date="releaseDisabled"
                        format="YYYY-MM-DD HH:mm"
                        v-model="release_time"
                        placeholder="选择时间"
                        :teleported="false"
                    />
                    <span class="icon_down">
                        <i class="iconfont icon-date"></i>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <el-button @click.stop="handleClose">取消</el-button>
            <el-button type="primary" @click.stop="confirm">确认</el-button>
        </template>
    </el-drawer>
</template>
<script>
import domain from '@/service/domain'
import { ElMessage, ElMessageBox } from "element-plus";
import { setIdeaReleaseTime } from "@api/index"
export default {
    props: {
        idea_id: {
            type: Number,
            default: 0
        },
        timeSubmitVisible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'release'
        }
    },
    emits:['update:timeSubmitVisible'],
    inject:['refresh'],
    data(){
        return {
            release_time:new Date(+new Date() + 60 * 60 * 1000)
        }
    },
    computed: {
        drawer:{
            get() {
                return this.timeSubmitVisible
            },
            set(val){
                this.$emit('update:timeSubmitVisible',val)
            }
        },
        title(){
            return this.type == 'release' ? '定时发布' : '修改定时'
        }
    },
    mounted(){

    },
    methods: {
        confirm(){
            if(new Date(this.release_time).getTime() < new Date(+new Date() + 60 * 60 * 1000)){
                ElMessageBox.alert('定时发布最短时间为1小时', '提示', {
                    confirmButtonText: '确定'
                })
                return false;
            }
            if(new Date(this.release_time).getTime() > new Date(+new Date() + 7 * 24 * 60 * 60 * 1000)){
                ElMessageBox.alert('定时发布最长可设置7天。', '提示', {
                    confirmButtonText: '确定'
                })
                return false;
            }
            this.releaseTime(this.$dayjs(this.release_time).format('YYYY-MM-DD HH:mm')+':00')
        },
        handleClose(){
            this.$emit("close");
        },
        releaseDisabled(time){
            // 大于7天禁用
            return time.getTime() < new Date(+new Date() - 1 * 24 * 60 * 60 * 1000) || time.getTime() > new Date(+new Date() + 7 * 24 * 60 * 60 * 1000)
        },
        // 定时发布
        async releaseTime(time) {
            const { status,message } = await setIdeaReleaseTime({
                idea_id:this.idea_id,
                release_time:time
            })
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: message || "操作成功",
                    type: "success",
                });
                this.$emit("close");
                this.refresh();
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        goLogin(message) {
            ElMessageBox.confirm(
                message,
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }
            )
            .then(() => {
                location.href = domain.account
            })
            .catch(() => {
            
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.dialogContent{
    >p{
        font-size: 14px;
        margin:0;
        font-weight: normal;
        color: #1A1A1A;
        line-height: 21px;
    }
    .time{
        .input{
            width:100%;
            margin: 20px auto 0;
            height: 36px;
            line-height: 36px;
            position: relative;
            cursor: pointer;
            &.hour{
                border: 1px solid #E8E8E8;
                border-radius: 4px;
                box-sizing: border-box;
                ::v-deep(.el-date-editor){
                    width: 100%;
                    height: 100%;
                    display: block;
                    cursor: pointer;
                    .el-input__wrapper{
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        box-shadow: none;
                        border-radius: 4px;
                    }
                    .el-input__inner{
                        border: 0;
                        padding: 0 10px !important;
                        line-height: 36px;
                        font-size: 16px;
                        height: 100%;
                        display: block;
                        cursor: pointer;
                    }
                    .el-input__prefix,.el-input__suffix{
                        display: none;
                    }
                }
            }
            .icon_down{
                position: absolute;
                top: 0;
                right: 11px;
                color: #7a8087;
                pointer-events: none;
            }
        }
    }
}
</style>