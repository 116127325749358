<template>
    <div class="idea-list">
        <template v-for="(item, index) in list" :key="index">
            <div class="idea-item">
                <div class="top">
                    <div class="cover" @click.stop="goIdeaDetail(item.idea_id)">
                        <img :src="$imgUrlFilter(item.logo, 100)" />
                        <p>{{item.current_stage}}</p>
                    </div>
                    <div class="info">
                        <h4 @click.stop="goIdeaDetail(item.idea_id)">{{ item.title }}</h4>
                        <div class="detail">
                            <div class="item">
                                <p>{{ goCatenameCN(item.category) }}</p>
                                <p>类型</p>
                            </div>
                            <div v-if="item.release_time" class="item">
                                <p class="release_time">{{ $dayjs(item.release_time).format('YYYY-MM-DD HH:mm') }}</p>
                                <p>定时发布时间</p>
                            </div>
                            <div v-else class="item">
                                <p>{{ item.create_time }}</p>
                                <p>发起时间</p>
                            </div>
                            <div class="item">
                                <p>{{ item.idea_id }}</p>
                                <p>创意ID</p>
                            </div>
                            <div class="item">
                                <p>{{ item.current_stage }}</p>
                                <p>当前阶段</p>
                            </div>
                            <div class="item">
                                <p>{{ item.update_count > 0 ? item.update_count + '次' : '无' }}</p>
                                <p>更新次数</p>
                            </div>
                            <div class="item">
                                <p>{{ item.last_update_time || '无' }}</p>
                                <p>最近更新日期</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="op">
                        <strong>{{ item.idea_status }}</strong>
                        <div v-if="item.btn_list && item.btn_list.length > 0" class="btn_list">
                            <div v-for="(btn, idx) in item.btn_list" :key="idx"
                                @click.stop="ideaClick(btn.action_type, item)">
                                {{ btn.action_name }}
                            </div>
                        </div>
                    </div>
                    <div v-if="item.refuse_reason" class="refuse_reason">
                        <template v-if="isURL(item.refuse_reason)">
                            <p>
                                <i class="iconfont icon-warning"></i>未通过审核原因：
                                <el-link :href="item.refuse_reason" target="_blank">点击查看详情</el-link>
                            </p>
                        </template>
                        <template v-else>
                            <p :class="{ 'limit': item.needOpen }">
                                <i class="iconfont icon-warning"></i>未通过审核原因：{{item.refuse_reason }}
                            </p>
                            <span v-if="item.needOpen" @click.stop="item.needOpen = false">
                                <el-icon>
                                <ArrowDown />
                                </el-icon>展开
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <time-idea-drawer :timeSubmitVisible="timeSubmitVisible" :type="timeDrawerType" :idea_id="time_idea_id"
            @close="timeSubmitVisible = false" @confirm="timeConfirm" />
    </div>
</template>

<script>
import domain from '@/service/domain'
import timeIdeaDrawer from "@@/user/timeIdeaDrawer.vue"
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { deleteIdea, ideaApplyReview, ideaToProjectDecision, ideaToProject } from "@api/index"
export default {
    props: {
        list: {
            type: Array,
        },
        category_list: {
            type: Array,
        }
    },
    components: {
        timeIdeaDrawer,
    },
    data() {
        return {
            time_idea_id: 0,
            timeSubmitVisible: false,
            timeDrawerType: 'release'
        }
    },
    mounted() {

    },
    methods: {
        goCatenameCN(en) {
            let result = en;
            this.category_list.forEach(ele => {
                if (ele.catename_en == en) {
                    result = ele.catename_ch;
                }
            });
            return result;
        },
        ideaClick(type, item) {
            console.log(type)
            switch (type) {
                // 编辑
                case 'edit':
                    window.location.href = `${domain.member}/initiate/idea_edit?idea_id=` + item.idea_id;
                    break;
                // 更新
                case 'update':
                    window.location.href = `${domain.member}/initiate/idea_update?idea_id=` + item.idea_id;
                    break;
                // 更新
                case 'add_draw':
                    window.location.href = `${domain.member}/operate_manage/drawtool/create?pro_id=${item.idea_id}&item_type=1`;
                    break;
                // 众筹决定
                case 'decide':
                    ElMessageBox.confirm(
                        '<p>恭喜，您的创意即将付诸实践啦～在发起众筹前请注意以下事项：</p><p style="padding-top:10px;">1．该操作将通知创意订阅及看好的用户已进入“众筹决定”阶段，即代表当前创意将启动众筹销售计划。</p><p style="padding-top:10px;">2．创意进入“众筹决定”状态后不可回退，请确认无误后操作。</p>',
                        '众筹决定',
                        {
                            confirmButtonText: '众筹决定',
                            cancelButtonText: '稍后再说',
                            dangerouslyUseHTMLString: true,
                        }
                    )
                        .then(() => {
                            this.projectDecision(item);
                        })
                        .catch(() => {

                        })
                    break;
                // 发起众筹
                case 'initiate':
                    if (Number(item.is_history_data) == 1) {
                        window.location.href = domain.account + '/initiate/base?temp_id=' + item.temp_id + '&pro_id=' + item.pro_id;
                    } else {
                        window.location.href = `${domain.account}/initiate/base?idea_id=` + item.idea_id;
                    }

                    break;
                // 我的众筹
                case 'project':
                    window.location.href = domain.account + '/initiate/base?temp_id=' + item.temp_id + '&pro_id=' + item.pro_id;
                    break;
                // 删除
                case 'delete':
                    ElMessageBox.confirm(
                        '是否确认删除',
                        '提示',
                        {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消'
                        }
                    )
                        .then(() => {
                            this.delIdea(item.idea_id);
                        })
                        .catch(() => {

                        })
                    break;
                // 申请人工复审
                case 'apply':
                    this.applyIdea(item.idea_id);
                    break;
                // 定时发布
                case 'release':
                    this.time_idea_id = item.idea_id;
                    this.timeSubmitVisible = true;
                    this.timeDrawerType = type;
                    break;
                // 修改定时
                case 'edit_release':
                    this.time_idea_id = item.idea_id;
                    this.timeSubmitVisible = true;
                    this.timeDrawerType = type;
                    break;
                // 历史更新
                case 'history_update':
                    localStorage.setItem('ideaItem', JSON.stringify(item));
                    this.$router.push({
                        name: 'idea_history',
                        query: {
                            idea_id: item.idea_id
                        }
                    });
                    break;
            }
        },
        // 删除创意
        async delIdea(id) {
            const { status, message } = await deleteIdea({
                idea_id: id
            })
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: "操作成功",
                    type: "success",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            } else if (status == 10000) {
                this.goLogin(message)
            } else {
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 申请人工审核
        async applyIdea(id) {
            const loadingInstance1 = ElLoading.service({ fullscreen: true })
            const { status, message } = await ideaApplyReview({
                idea_id: id
            })
            loadingInstance1.close()
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: "操作成功",
                    type: "success",
                });
                this.$emit("change");
            } else if (status == 10000) {
                this.goLogin(message)
            } else {
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 创意转众筹
        async ideaToProjectFun(pro_id) {
            const { status, message } = await ideaToProject({
                pro_id: pro_id
            })
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: "操作成功",
                    type: "success",
                });
                this.$emit("change");
            } else if (status == 10000) {
                this.goLogin(message)
            } else {
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 众筹决定
        async projectDecision(item) {
            const { status, message } = await ideaToProjectDecision({
                idea_id: item.idea_id
            })
            if (status == 0) {
                if (Number(item.is_history_data) == 1) {
                    this.ideaToProjectFun(item.pro_id)
                } else {
                    ElMessage({
                        showClose: true,
                        message: "操作成功",
                        type: "success",
                    });
                    this.$emit("change");
                }

            } else if (status == 10000) {
                this.goLogin(message)
            }
            else {
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 前往详情
        goIdeaDetail(id) {
            let newTab = window.open('about:blank');
            let url = 'https://m.modian.com/idea/' + id;
            if (/test/.test(location.host)) {
                url = 'https://testm.modianinc.com/idea/' + id;
            }
            newTab.location.href = url
        },
        goLogin(message) {
            ElMessageBox.confirm(
                message,
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }
            )
                .then(() => {
                    location.href = domain.account
                })
                .catch(() => {

                })
        },
        isURL(str) {
            const pattern = /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
            return pattern.test(str);
        },
        async timeConfirm() { }
    }
}
</script>

<style lang="scss" scoped>
.idea-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0 40px 0;

    .idea-item {
        width: 100%;
        padding-top: 40px;

        .top {
            height: 100px;
            display: flex;
            align-items: center;
            .cover{
                width: 100px;
                height: 100px;
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                &::after{
                    content:'';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100px;
                    height: 50px;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
                    z-index: 1;
                }
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
                p{
                    margin: 0;
                    position: absolute;
                    font-size: 16px;
                    width: 100%;
                    left: 0;
                    bottom: 10px;
                    text-align: center;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 16px;
                    z-index: 2;
                }
            }
            .info {
                margin: 0 0 0 20px;

                h4 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: #1A1A1A;
                    line-height: 18px;
                }

                .detail {
                    padding: 23px 0 0 0;
                    display: flex;
                    align-items: center;

                    .item {
                        padding: 0 40px 0 0;

                        &:last-child {
                            padding: 0;
                        }

                        p {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 400;
                            color: #1A1A1A;
                            line-height: 16px;

                            &.release_time {
                                color: #F5A728;
                            }

                            &:last-child {
                                padding: 9px 0 0 0;
                                font-size: 14px;
                                font-weight: 400;
                                color: #7F7F7F;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            width: 800px;
            background: #F8F8F8;
            border-radius: 8px 8px 8px 8px;
            margin: 10px 0 0 0;

            .op {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                strong {
                    padding: 0 0 0 20px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #1A1A1A;
                    line-height: 18px;
                }

                .btn_list {
                    display: flex;
                    align-items: center;

                    >div {
                        padding: 0 15px;
                        margin: 0 20px 0 0;
                        min-width: 94px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #B1B1B1;
                        font-size: 16px;
                        font-weight: 400;
                        color: #7F7F7F;
                        line-height: 38px;
                        cursor: pointer;
                        text-align: center;
                        &:hover{
                            border: 1px solid #1A1A1A;
                            color: #1A1A1A;
                        }
                    }
                }
            }

            .refuse_reason {
                border-top: 1px solid #E8E8E8;
                padding: 16px 20px;
                position: relative;

                p {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                    color: #7F7F7F;
                    line-height: 1.5;

                    &.limit {
                        width: 688px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    i {
                        font-size: 16px;
                        padding-right: 10px;
                        color: #B1B1B1;
                    }
                }

                span {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    font-size: 16px;
                    font-weight: 400;
                    color: #00C4A1;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    i {
                        font-size: 16px;
                        padding-right: 6px;
                    }
                }
            }
        }
    }
}
</style>
